<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="employeeData.avatar"
            :text="avatarText(employeeData.last_name + ' ' + employeeData.first_name)"
            :variant="`light-${resolveEmployeeStatusVariant(employeeData.contract_type)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                <i>{{ sex }}</i>
                {{ employeeData.last_name + ' ' + employeeData.first_name }}
              </h4>
              <span class="card-text">{{ employeeData.job_title }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-employees-edit', params: { id: employeeData.uid } }"
                variant="primary"
              >
                Modifier
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Supprimer
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ amountToCurrency(employeeData.salary) }}
              </h5>
              <small>Salaire Mensuel</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                ----- %
              </h5>
              <small>Evaluation</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Matricule</span>
            </th>
            <td class="pb-50">
              <b-badge variant="light-primary">
                #{{ employeeData.matricule }}
              </b-badge>
              <i>
                ({{ employeeType }})
              </i>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Date Emb.</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ formatDate(employeeData.hire_date) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookmarkIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Ancienneté</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ moment(employeeData.hire_date, "YYYY-MM-DD").fromNow(true) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="InfoIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Âge</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ moment(employeeData.birth_date, "YYYY-MM-DD").fromNow(true) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Niv. Étude</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ employeeData.grade }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="GitPullRequestIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Département</span>
            </th>
            <td class="pb-50">
              {{ employeeData.department.name }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="GitCommitIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Service</span>
            </th>
            <td>
              {{ employeeData.service != null ? employeeData.service.name : '-----' }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useEmployeesList from '../employees-list/useEmployeesList'
import { computed } from '@vue/composition-api'
import { amountToCurrency } from "@/utils/formatAmount";
import { formatDate } from '@/@core/utils/filter'
import moment from 'moment'
moment.locale("fr")

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveEmployeeStatusVariant } = useEmployeesList()
    const sex = computed(() => {
      switch (props.employeeData.sex) {
        case 'female':
          return 'Mme.'
          break;
        case 'male':
          return 'M.'
          break;
      
        default:
          return ''
          break;
      }
    })

    const employeeType = computed(() => {
      return props.employeeData.employee_type == 'contractor' ? 'Prestataire' : 'Agent'
    })
    return {
      formatDate,
      moment,
      sex,
      employeeType,
      avatarText,
      resolveEmployeeStatusVariant,
      amountToCurrency,
    }
  },
}
</script>