<template>
  <div class="d-flex flex-wrap">
    <b-button
      class="mb-1"
      variant="primary"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      block
      @click="openModal = true"
    >
      <feather-icon icon="CreditCardIcon" class="mr-50" />
      <span class="align-middle">Payer Salaire</span>
    </b-button>
    <validation-observer ref="refSalaryFormObserver" #default="{ invalid }">
      <b-modal
        v-model="openModal"
        size="lg"
        cancel-variant="outline-secondary"
        ok-title="Payer"
        :ok-disabled="invalid || isLoading || salaryDetails.net_salary <= 0"
        cancel-title="Annuler"
        centered
        title="Payer un salaire"
        ref="salary-modal"
        @ok.prevent="onSubmit"
        @show="resetModal"
        @hidden="openModal = false"
      >
        <span v-if="salaryDetails.net_salary <= 0"
          ><strong class="text-danger">*</strong
          ><i
            >Veuillez mettre à jour l'état de paiement de l'employé(e) avant
            tout paiement.</i
          >
        </span>
        <b-form v-else>
          <b-row>
            <b-col cols="12" lg="8">
              <!-- Amount -->
              <b-form-group
                label="Salaire Net :"
                label-for="amount"
                :description="`CNSS Employé(e) : ${employeeCnss} | ITS : ${its} | VPS : ${vps}`"
                disabled
              >
                <validation-provider
                  #default="{ errors }"
                  name="amendment"
                  rules="required"
                >
                  <b-form-input
                    id="amount"
                    type="text"
                    placeholder="Le montant du salaire"
                    :value="netSalary"
                    disabled
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="4">
              <!-- Salary Month -->
              <b-form-group label="Mois Concerné :" label-for="concerned_month">
                <validation-provider
                  #default="{ errors }"
                  name="concerned_month"
                  rules="required"
                >
                  <flat-pickr
                    id="concerned_month"
                    v-model="salary.concerned_month"
                    placeholder="Mois à payer"
                    :config="flatConfig"
                    class="form-control"
                  />

                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <!-- Mutuel -->
              <b-form-group label="Mutuel :" label-for="mutual" disabled>
                <validation-provider
                  #default="{ errors }"
                  name="mutual"
                  rules=""
                >
                  <b-form-input
                    v-model="salary.mutual"
                    id="mutual"
                    type="number"
                    placeholder="Le montant du mutuel"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <!-- Bonus -->
              <b-form-group label="Prime :" label-for="bonus" disabled>
                <validation-provider
                  #default="{ errors }"
                  name="bonus"
                  rules="required"
                >
                  <b-form-input
                    v-model="salary.bonus"
                    id="bonus"
                    type="number"
                    placeholder="Le montant de la prime"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group
            label="Effectuer le Paiement Via"
            label-for="payment-method"
          >
            <validation-provider
              #default="{ errors }"
              name="Paiement"
              rules="required"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="payments"
                v-model="salary.payment_method"
                label="label"
                input-id="payment-method"
                class="payment-selector"
                :reduce="(option) => option.value"
                :clearable="false"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BButton,
  VBToggle,
  BModal,
  BForm,
  BFormInvalidFeedback,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import useEmployeesList from "../employees-list/useEmployeesList";
import { amountToCurrency } from "@/utils/formatAmount";

import moment from "moment";
moment.locale("fr");

export default {
  name: "EmployeeViewSalaryModal",
  components: {
    BButton,
    BModal,
    BForm,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    // 3rd party packages
    flatPickr,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    emit: "salaryPaid",
  },
  props: {
    salaryDetails: {
      type: Object,
      required: true,
    },
    hireDate: {
      type: String,
      required: true,
    },
    lastWage: {
      type: Object,
      required: true,
    },
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      required,
      flatConfig: {
        wrap: true,
        altFormat: "m-Y",
        dateFormat: "m-Y",
        minDate: this.lastPaymentDate(),
        maxDate: new Date(),
        locale: French, // locale for this instance only
      },
      openModal: false,
      isLoading: false,

      //salary
      salary: {
        payment_method: "",
        concerned_month: null,
        bonus: 0,
        mutual: 0,
      },
    };
  },
  computed: {
    netSalary() {
      return amountToCurrency(this.salaryDetails.net_salary);
    },
    employeeCnss() {
      return amountToCurrency(this.salaryDetails.employee_cnss_amount);
    },
    vps() {
      return amountToCurrency(this.salaryDetails.vps_amount);
    },
    its() {
      return amountToCurrency(this.salaryDetails.its);
    },
    payments() {
      const { paymentMethods } = useEmployeesList();
      return paymentMethods;
    },
  },
  methods: {
    lastPaymentDate() {
      if (Object.keys(this.lastWage).length > 0) {
        const date = this.lastWage.details?.concerned_month;
        return moment(date, "MM-YYYY").add(1, "M").format("MM-YYYY");
      }
      return moment(this.hireDate, "YYYY-MM-DD").format("MM-YYYY");
    },
    // send salary paiement request to the API
    async onSubmit() {
      const isValid = this.$refs.refSalaryFormObserver.validate();
      if (!isValid) return;

      this.isLoading = true;

      try {
        const response = await this.$store.dispatch("employee/paidSalary", {
          uid: this.$route.params.id,
          salary: this.salary,
        });

        if (!response.success) {
          this.handleErrors(response.errors);
          this.showToast(
            "Une erreur est survenue lors de l'enregistrement du paiement.",
            "error"
          );
          return;
        }

        this.handleSuccess();
      } catch (error) {
        this.showToast(
          "Une erreur est survenue lors de l'enregistrement du paiement.",
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },
    resetModal() {
      this.$refs.refSalaryFormObserver.reset();
      this.salary = {
        payment_method: "",
        concerned_month: null,
        bonus: 0,
        mutual: 0,
      };
    },
    handleErrors(errors) {
      for (const [field, message] of Object.entries(errors)) {
        this.$refs.refSalaryFormObserver.setErrors({ [field]: message[0] });
      }
    },
    handleSuccess() {
      this.$emit("salaryPaid");
      this.showToast("Paiement enregistré avec succès", "success");
      this.resetModal();
      this.openModal = false; // Close modal on successful submission
    },
    showToast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "CoffeeIcon",
          variant: variant,
        },
      });
    },
  },
};
</script>
