<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="employeeData === undefined">
      <h4 class="alert-heading">Erreur de récupération de données</h4>
      <div class="alert-body">
        Aucun employé trouvé pour cet ID. Vérfiez
        <b-link class="alert-link" :to="{ name: 'apps-employees-list' }">
          la Liste du Personnel
        </b-link>
        pour d'autres employées.
      </div>
    </b-alert>

    <template v-if="employeeData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="9" lg="8" md="7">
          <employee-view-employee-info-card :employee-data="employeeData" />
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <employee-view-employee-plan-card @employeeUpdated="getEmployeeData" :employee-data="employeeData" />
        </b-col>
      </b-row>

      <b-row>
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="MenuIcon" />
              <span>Salaires</span>
            </template>

            <custom-list
              ref="refWagesHistoryListTable"
              concernedList="historiques"
              :items="getWagesHistories"
              :total="totalWagesHistories"
              :fields="wagesHistoryFields"
              :perPage.sync="whPerPage"
              :pageOptions="whPageOptions"
              :currentPage.sync="whCurrentPage"
              :sortBy.sync="whSortBy"
              :sortDesc.sync="whSortDirDesc"
              :sortDirection="whSortDirection"
              :filter.sync="whFilter"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="MenuIcon" />
              <span>Congés</span>
            </template>

            <custom-list
              ref="refLeavesHistoryListTable"
              concernedList="leaves"
              :items="getLeavesHistories"
              :total="totalLeavesHistories"
              :fields="leavesHistoryFields"
              :perPage.sync="lhPerPage"
              :pageOptions="lhPageOptions"
              :currentPage.sync="lhCurrentPage"
              :sortBy.sync="lhSortBy"
              :sortDesc.sync="lhSortDirDesc"
              :sortDirection="lhSortDirection"
              :filter.sync="lhFilter"
            />
          </b-tab>
        </b-tabs>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink, BTabs, BTab } from "bootstrap-vue";
import CustomList from "@/components/fund-history/customList.vue";
import EmployeeViewEmployeeInfoCard from "./EmployeeViewEmployeeInfoCard.vue";
import EmployeeViewEmployeePlanCard from "./EmployeeViewEmployeePlanCard.vue";
import useEmployeesList from "../employees-list/useEmployeesList";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,

    // Local Components
    EmployeeViewEmployeeInfoCard,
    EmployeeViewEmployeePlanCard,

    CustomList,
  },
  setup() {
    const employeeData = ref(null);

    const {
      // data
      getWagesHistories,
      getLeavesHistories,

      // table options
      wagesHistoryFields,
      leavesHistoryFields,

      whPerPage,
      lhPerPage,

      whCurrentPage,
      lhCurrentPage,

      totalWagesHistories,
      totalLeavesHistories,

      whPageOptions,
      lhPageOptions,

      whSearchQuery,
      lhSearchQuery,

      whSortBy,
      lhSortBy,

      whSortDirDesc,
      lhSortDirDesc,
      whSortDirection,
      lhSortDirection,
      refWagesHistoryListTable,
      refLeavesHistoryListTable,

      refetchWagesData,
      refetchLeavesData,

      // Extra Filters
      whFilter,
      lhFilter,
    } = useEmployeesList();

    const getEmployeeData = () => {
      store
        .dispatch("employee/fetchEmployee", { id: router.currentRoute.params.id })
        .then((response) => {
          employeeData.value = response;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            employeeData.value = undefined;
          }
        });
    }

    getEmployeeData()


    return {
      employeeData,
      getWagesHistories,
      getLeavesHistories,
      getEmployeeData,

      // table options
      wagesHistoryFields,
      leavesHistoryFields,

      whPerPage,
      lhPerPage,

      whCurrentPage,
      lhCurrentPage,

      totalWagesHistories,
      totalLeavesHistories,

      whPageOptions,
      lhPageOptions,

      whSearchQuery,
      lhSearchQuery,

      whSortBy,
      lhSortBy,

      whSortDirDesc,
      lhSortDirDesc,
      whSortDirection,
      lhSortDirection,
      refWagesHistoryListTable,
      refLeavesHistoryListTable,

      refetchWagesData,
      refetchLeavesData,

      // Extra Filters
      whFilter,
      lhFilter,
    };
  },
};

// leaves crud
// update employee table => salary, last_salary_paid, leaves, remain_leaves
</script>