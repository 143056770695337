<template>
  <b-card no-body class="border-primary">
    <b-card-header
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <h5 class="mb-0">
        Contrat : {{ employeeData.contract_type.toUpperCase() }}
      </h5>
      <small class="text-muted w-100">{{ startDate }} - {{ endDate }}</small>
    </b-card-header>

    <b-card-body class="mt-1">
      <employee-view-salary-modal @salaryPaid="$emit('employeeUpdated')" :salaryDetails="employeeData.salary_details" :hireDate="employeeData.hire_date" :lastWage="employeeData.last_wage || {}" />
      <b-button
        :to="{
          name: 'apps-employees-salary-statement',
          params: { id: employeeData.uid },
        }"
        class="mb-1"
        variant="info"
        block
      >
        <feather-icon icon="FileTextIcon" class="mr-50" />
        <span class="align-middle">État de Salaire</span>
      </b-button>
      <b-button
        :to="{
          name: 'apps-employees-leaves',
          params: { id: employeeData.uid },
        }"
        variant="warning"
        block
      >
        <feather-icon icon="LogOutIcon" class="mr-50" />
        <span class="align-middle">Congés</span>
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BBadge, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { computed } from "@vue/composition-api";
import { formatDate } from "@/@core/utils/filter";
import moment from "moment";
import EmployeeViewSalaryModal from './EmployeeViewSalaryModal.vue';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    EmployeeViewSalaryModal,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const startDate = computed(() => {
      return formatDate(props.employeeData.contract_start_date);
    });
    const endDate = computed(() => {
      if (props.employeeData.contract_end_date != null)
        return formatDate(props.employeeData.contract_end_date);
      else return "Indéterminée";
    });

    return {
      startDate,
      endDate,
    };
  },
};
</script>
